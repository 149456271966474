<template>
  <div class="container">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="content" @submit.prevent="handleSubmit(access)">
        <h4>Ingresa Codigo</h4>
        <div>
          <ValidationProvider name="contraseña" rules="required|max:16" v-slot="{ errors }">
            <input type="password" v-model="contra">
            <span class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <button type="submit">Ingresar</button>
      </form>
    </ValidationObserver>
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <span class="close" @click="showModal = false">&times;</span>
        <br><br>
        <div class="parent">
          <input type="text" v-model="correlativo" placeholder="Correlativo">
          <button style="justify-self: end;" @click="resumeQuiz()">Reanudar Examen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import route from "../router/index";
import hotkeys from 'hotkeys-js';

export default {
  name: 'HelloWorld',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      contra: "",
      showModal: false,
      correlativo: ''
    }
  },
  methods: {
    access() {

      var data = {
        Code: this.contra
      };

      let url = process.env.VUE_APP_API_DEV ? `${process.env.VUE_APP_API_DEV}Examen/Access` : `${process.env.VUE_APP_API_PRO}Examen/Access`;
      axios.post(url, data).
        then((res) => {
          if (res.data) {
            route.push({ name: "welcome" });
          }
        })
        .catch((error) => console.error(error));
    },

    resumeQuiz() {
      let url = process.env.VUE_APP_API_DEV ? `${process.env.VUE_APP_API_DEV}ResumenQuiz` : `${process.env.VUE_APP_API_PRO}ResumenQuiz`;
      let data = {
        correlativo: this.correlativo
      }
      axios.post(url, data)
      .then((result) => {
        route.push({ name: "quiz", params: { idExaminado: result.data.IdExaminado, idLicencia: result.data.IdLicencia, correlativo: result.data.Correlativo } });
      }).catch((err) => {
        console.error(err)
      });
    }
  },
  mounted() {
    hotkeys('alt+r, alt+R', () => {
      this.showModal = true;
    });
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Establecer la altura del contenedor */
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3em 5em 2.5em;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  justify-items: center;
  align-items: center;
}

.parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 2.3rem;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
}
</style>
