import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Examen/Bienvenida',
    name: 'welcome',
    component: () => import('../views/WelcomeView.vue')
  },
  {
    path: '/Examen/NuevoExaminado',
    name: 'newQuiz',
    component: () => import('../views/ExaminadoView.vue')
  },
  {
    path: '/Indicaciones/:idExaminado/:idLicencia/:correlativo',
    name: 'indications',
    component: () => import('../views/IndicacionesView.vue')
  },
  {
    path: '/Examen/:idExaminado/:idLicencia/:correlativo',
    name: 'quiz',
    component: () => import('../views/ExamenView.vue')
  },
  {
    path: '/Examen/:id/:correlativo',
    name: 'endQuiz',
    component: () => import('../views/EndQuizView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
