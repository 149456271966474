import { required, confirmed, length, email, max, min_value, regex } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "El campo {_field_} es obligatorio"
});

extend("email", {
  ...email,
  message: "El campo {_field_} debe ser un correo electrónico válido"
});

extend("confirmed", {
  ...confirmed,
  message: "El campo {_field_} no coincide"
});

extend("length", {
  ...length,
  message: "El largo del campo {_field_} debe ser {length}"
});

extend("max", {
  ...max,
  message: "El campo {_field_} no debe ser mayor a {length} caracteres"
});

extend("select", {
  ...min_value,
  message: "El campo {_field_} es obligatorio"
});

extend("nums", {
  ...regex,
  message: "El campo {_field_} solo permite numeros"
});

extend("dui", {
  ...regex,
  message: "El campo {_field_} no tiene el formato de 00000000-0"
});

